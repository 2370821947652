import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#4286F4",
    },
    secondary: {
      main: "#fabf5f",
    },
    // error: {
    //   main: "#dd4b39",
    // },
    background: {
      default: "#fff",
    },
  },
});

export default theme;
